import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import { Button, Input, Progress, Select, Switch, Table, Tooltip } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import IntlMessages from "@uni/utility/IntlMessages";
import { conditionsMap1, conditionsMap2 } from "../constant";
import QueueAnim from "rc-queue-anim";
import "../index.style.less";
import AppButtonActions from "@uni/core/AppButtonAction";
import useIntl from "react-intl/lib/src/components/useIntl";
import AppCustomModalWrapper from "@uni/core/AppCustomModalWrapper";
import ApplyAllModal from "../ApplyAllModal";
import usePostBangkeDefault from "hooks/apis/bangke/usePostBangKeDefault";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import useGetAllPsr from "hooks/apis/psr/useGetAllPsr";

const StepTwo = () => {
  const { messages } = useIntl();
  const applyAllModalRef = useRef();
  const { prevStep, dataRow, setDataRow, formData, nextStep, setResult } =
    useGenerateContext();

  const { companyManagement } = useLayoutContext();
  const { mutateAsync, isLoading } = usePostBangkeDefault();

  const { data } = useGetAllPsr({
    hs: [...dataRow].map((record) => record?.hs_code || "").join(", ") || "",
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
  });

  const selectedFormData = useMemo(() => formData?.select_form, [formData]);

  const handleSubmitStep2 = async () => {
    try {
      const formattedData = dataRow?.map((row) => ({
        ecus_id: row?._id,
        form_criteria: row?.form_type,
        criteria_value: row?.criteria,
        form_type:
          formData.select_form === "UKV" || formData.select_form === "EV"
            ? "EXW"
            : "FOB",
        custom_input: row?.custom_input,
        origin_option: formData.origin_option,
        price_option: formData.price_option,
        select_form: formData.select_form,
        data_option: formData.data_option,
        begin_option: formData.begin_option,
        contract_option: formData.contract_option,
        start_date: formData.start_date.format("DD-MM-YYYY"),
        folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
        company_slug: companyManagement?.company?.detail?.company?.slug || "",
        insert_form_type: row.insertType,
        sedo_option: row?.sedo_option,
        progress_price: Number(row?.progress_price),
        domestic_expenses: row?.domestic_expenses,
        freight: row?.freight,
        insurance: row?.insurance,
        oversea_expenses: row?.oversea_expenses,
        imported_expenses: row?.imported_expenses,
        url_option: formData.url_option,
      }));
      const res = await mutateAsync({ data: formattedData });
      await setResult(res);
      nextStep();
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (key, index) => (value) => {
    const newData = [...dataRow];
    if (value && value.target !== undefined) {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }
    if (key === "insertType" && value === "formula") {
      delete newData[index].custom_input;
      delete newData[index].freight;
      delete newData[index].insurance;
      delete newData[index].oversea_expenses;
      delete newData[index].imported_expenses;
    }

    if (key === "insertType" && value === "user_input") {
      delete newData[index].domestic_expenses;
    }
    if (key === "sedo_option" && value !== true) {
      delete newData[index].progress_price;
    }
    if (key === "sedo_option" && value === true) {
      newData[index].form_type = "RVC";
    }
    setDataRow(newData);
  };

  const columns = [
    {
      title: <IntlMessages id="common.no" />,

      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: <IntlMessages id="determination.determine.code" />,
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: <IntlMessages id="determination.determine.itemName" />,
      dataIndex: "item_name",
      key: "item_name",
      width: 200,
      // ellipsis: true,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <div style={{ width: "200px" }} className="ellipsis">
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: <IntlMessages id="determination.determine.hsCode" />,
      dataIndex: "hs_code",
      key: "hs_code",
      width: 90,
    },
    {
      dataIndex: "criteria",
      title: <IntlMessages id="determination.determine.criteria" />,
      width: 150,
      render: (text, record, index) => (
        <Input value={text} onChange={onChange("criteria", index)} required />
      ),
    },
    {
      dataIndex: "hs_code",
      title: "PSR",
      width: 150,
      render: (hs) => {
        const text = data?.[hs]?.[selectedFormData] || "";
        return (
          <Tooltip placement="topLeft" title={text}>
            <div style={{ width: "200px" }} className="ellipsis">
              {text}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <IntlMessages id="determination.determine.insertType" />,
      dataIndex: "insertType",
      key: "insertType",
      width: 150,
      render: (text, record, index) => (
        <Select
          style={{ width: "100%" }}
          value={text}
          defaultValue={"formula"}
          onChange={onChange("insertType", index)}
          options={[
            { value: "formula", label: "Formula" },
            { value: "user_input", label: "User Input" },
          ]}
        />
      ),
    },
    {
      title: <IntlMessages id="determination.determine.value" />,
      key: "value",
      width: "max-content",
      render: (text, record, index) => {
        const { invoice_price_condition } = record;
        const conditionsMap =
          formData.select_form === "UKV" || formData.select_form === "EV"
            ? conditionsMap2
            : conditionsMap1;
        const fields = conditionsMap[invoice_price_condition] || [];

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: record.insertType !== "formula" ? 150 : "fit-content",
            }}
          >
            {record.insertType === "formula" ? (
              fields.map((field, idx) => (
                <React.Fragment key={idx}>
                  {["+", "-"].includes(field) ? (
                    <span className="mx-2">{field}</span>
                  ) : (
                    <Input
                      type="number"
                      value={record[field]}
                      disabled={field === "nt_value"}
                      placeholder={field.replace("_", " ").toUpperCase()}
                      onChange={onChange(field, index)}
                      className="mr-2"
                      style={{ width: "150px" }}
                    />
                  )}
                </React.Fragment>
              ))
            ) : (
              <Input
                placeholder="Custom Input"
                name="custom_input"
                type="number"
                value={record?.custom_input}
                onChange={onChange("custom_input", index)}
                style={{ width: "150px" }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: <IntlMessages id="determination.determine.formType" />,
      dataIndex: "form_type",
      key: "form_type",
      width: 200,
      render: (text, record, index) => (
        <Select
          value={record?.sedo_option === true ? "RVC" : text}
          disabled={record?.sedo_option === true}
          defaultValue={"CTC"}
          style={{ width: "100%" }}
          onChange={onChange("form_type", index)}
          options={[
            { value: "CTC", label: "CTC" },
            { value: "RVC", label: "RVC" },
          ]}
        />
      ),
    },
    ...(formData.url_option === "custom"
      ? [
          {
            dataIndex: "progress_price",
            key: "progress_price",
            title: <IntlMessages id="determination.determine.progressPice" />,
            width: 200,
            render: (text, record, index) => (
              <Input
                placeholder="Progress Price"
                type="number"
                disabled={!record?.sedo_option}
                value={text}
                onChange={onChange("progress_price", index)}
              />
            ),
          },
        ]
      : []),
    ...(formData.url_option === "custom"
      ? [
          {
            title: <IntlMessages id="determination.determine.sedo" />,
            dataIndex: "sedo_option",
            key: "sedo_option",
            width: 120,
            render: (text, record, index) => (
              <Switch
                checked={record?.sedo_option}
                onChange={onChange("sedo_option", index)}
              />
            ),
          },
        ]
      : []),
  ];
  const [progressData, setProgressData] = useState([]);
  useEffect(() => {
    if (isLoading) {
      const initialProgressData = dataRow.map((item) => ({
        id: item.code,
        percent: 0,
      }));
      setProgressData(initialProgressData);

      const intervals = dataRow.map((item, index) => {
        return setInterval(() => {
          setProgressData((prev) => {
            const newProgress = [...prev];
            newProgress[index].percent = Math.min(
              newProgress[index].percent + Math.random() * 10,
              100
            );
            return newProgress;
          });
        }, 500);
      });

      return () => intervals.forEach(clearInterval);
    } else {
      setProgressData([]);
    }
  }, [isLoading, dataRow]);

  const handleBack = () => {
    // const newData = dataRow.map((item) => {
    //   if (item.sedo_option !== undefined) {
    //     delete item.sedo_option;
    //     delete item.progress_price;
    //   }
    //   return item;
    // });
    setDataRow(dataRow);
    prevStep();
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            minHeight: "300px",
          }}
        >
          {progressData?.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                width: "600px",
              }}
            >
              <p
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "150px",
                }}
              >
                {item.id}
              </p>
              <Progress
                // type="circle"
                percent={item?.percent}
                status={item?.percent >= 100 ? "success" : "active"}
                showInfo={false}
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              paddingBottom: "8px",
            }}
          >
            <AppButtonActions
              // icon={<ToolOutlined />}
              title={messages["common.applyAll"]?.toString()}
              disabled={!dataRow?.length}
              type="default"
              onClick={() => applyAllModalRef.current?.openModal()}
            />
          </div>
          {/* <AppsContent fullView> */}
          <QueueAnim>
            <Table
              scroll={{ x: "max-content", y: "calc(100vh - 500px)" }}
              className="table-step-two"
              rowKey="id"
              columns={columns}
              dataSource={dataRow}
              pagination={false}
            />
          </QueueAnim>

          {/* </AppsContent> */}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              gap: "8px",
              paddingTop: "24px",
              height: "100%",
            }}
          >
            <Button type="default" onClick={handleBack}>
              <IntlMessages id="common.back" />
            </Button>
            <Button onClick={handleSubmitStep2} type="primary">
              <IntlMessages id="common.continue" />
            </Button>
          </div>
          <AppCustomModalWrapper ref={applyAllModalRef}>
            <ApplyAllModal />
          </AppCustomModalWrapper>
        </div>
      )}
    </>
  );
};

export default StepTwo;
