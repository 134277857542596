export const conditionsMap1 = {
  EXW: ["nt_value", "+", "domestic_expenses"],
  FCA: ["nt_value", "+", "domestic_expenses"],
  FAS: ["nt_value", "+", "domestic_expenses"],
  CFR: ["nt_value", "-", "freight"],
  CIF: ["nt_value", "-", "freight", "-", "insurance"],
  CPT: ["nt_value", "-", "oversea_expenses"],
  CIP: ["nt_value", "-", "freight", "-", "oversea_expenses", "-", "insurance"],
  DAP: ["nt_value", "-", "imported_expenses"],
  DDU: ["nt_value", "-", "freight", "-", "oversea_expenses"],
  DDP: ["nt_value", "-", "freight", "-", "oversea_expenses"],
  FOB: ["nt_value"],
};

export const conditionsMap2 = {
  FOB: ["nt_value", "-", "domestic_expenses"],
  FCA: ["nt_value", "-", "domestic_expenses"],
  FAS: ["nt_value", "-", "domestic_expenses"],
  CFR: ["nt_value", "-", "freight", "-", "domestic_expenses"],
  CIF: ["nt_value", "-", "freight", "-", "insurance", "-", "domestic_expenses"],
  CPT: [
    "nt_value",
    "-",
    "freight",
    "-",
    "oversea_expenses",
    "-",
    "domestic_expenses",
  ],
  CIP: [
    "nt_value",
    "-",
    "freight",
    "-",
    "oversea_expenses",
    "-",
    "insurance",
    "-",
    "domestic_expenses",
  ],
  DAP: ["nt_value", "-", "imported_expenses", "-", "domestic_expenses"],
  DDU: [
    "nt_value",
    "-",
    "freight",
    "-",
    "oversea_expenses",
    "-",
    "domestic_expenses",
  ],
  DDP: [
    "nt_value",
    "-",
    "freight",
    "-",
    "oversea_expenses",
    "-",
    "domestic_expenses",
  ],
  EXW: ["nt_value"],
};

export const SelectForm = [
  { label: "D", value: "ATIGA" },
  { label: "EUR1.UK", value: "UKV" },
  { label: "EUR.1", value: "EV" },
  { label: "EAV", value: "EUEA" },
  { label: "E", value: "AC" },
  { label: "AANZ", value: "AANZ" },
  { label: "AHK", value: "AHK" },
  { label: "CPTPP", value: "CPTPP" },
  { label: "VC", value: "VC" },
  { label: "VK", value: "VK" },
  { label: "RCEP", value: "RCEP" },
  { label: "VJ", value: "VJ" },
  { label: "AJ", value: "AJ" },
  { label: "AK", value: "AK" },
];

export const OptionForm = [
  { label: "ECUS", value: "ecus" },
  { label: "PURCHASE", value: "purchase" },
  { label: "DEFAULT", value: "default" },
];

export const OptionOrigin = [
  { label: "ORIGINATED", value: "originated" },
  { label: "NON_ORIGINATED", value: "non_originated" },
];

export const OptionPrice = [
  { label: "LOW_PRICE", value: "low_price" },
  { label: "HIGH_PRICE", value: "high_price" },
];

export const DisplayOption = [
  { label: "Custom - SEDO", value: "custom" },
  { label: "Default", value: "default" },
];
